import { progress } from '../utils'

export const traffic = [
  {
    id: 0,
    product: 'Bento Matte 3D Illustration',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-1.jpg',
    image2x: '/images/content/product-pic-1@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Market',
        color: '#FFBC99',
        counter: 550000,
        width: '20%',
      },
      {
        title: 'alexmchan',
        color: '#B1E5FC',
        counter: 512350,
        width: '20%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '60%',
      },
    ],
  },
  {
    id: 1,
    product: 'Node - Crypto iOS UI design kit',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-2.jpg',
    image2x: '/images/content/product-pic-2@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Market',
        color: '#FFBC99',
        counter: 550000,
        width: '20%',
      },
      {
        title: 'Social media',
        color: '#CABDFF',
        counter: 320000,
        width: '12%',
      },
      {
        title: 'Direct',
        color: '#B5E4CA',
        counter: 650550,
        width: '30%',
      },
      {
        title: 'alexmchan',
        color: '#B1E5FC',
        counter: 512350,
        width: '20%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '18%',
      },
    ],
  },
  {
    id: 2,
    product: 'TaskEz: Productivity App iOS UI Kit',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-3.jpg',
    image2x: '/images/content/product-pic-3@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Social media',
        color: '#CABDFF',
        counter: 320000,
        width: '32%',
      },
      {
        title: 'Direct',
        color: '#B5E4CA',
        counter: 650550,
        width: '30%',
      },
      {
        title: 'alexmchan',
        color: '#B1E5FC',
        counter: 512350,
        width: '20%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '18%',
      },
    ],
  },
  {
    id: 3,
    product: 'Bento Matte 3D Illustration',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-4.jpg',
    image2x: '/images/content/product-pic-4@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Direct',
        color: '#B5E4CA',
        counter: 650550,
        width: '30%',
      },
      {
        title: 'alexmchan',
        color: '#B1E5FC',
        counter: 512350,
        width: '20%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '50%',
      },
    ],
  },
  {
    id: 4,
    product: 'Academe 3D Education Icons',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-5.jpg',
    image2x: '/images/content/product-pic-5@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Market',
        color: '#FFBC99',
        counter: 550000,
        width: '40%',
      },
      {
        title: 'Social media',
        color: '#CABDFF',
        counter: 320000,
        width: '12%',
      },
      {
        title: 'Direct',
        color: '#B5E4CA',
        counter: 650550,
        width: '30%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '18%',
      },
    ],
  },
  {
    id: 5,
    product: 'Bento Matte 3D Illustration',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-6.jpg',
    image2x: '/images/content/product-pic-6@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Market',
        color: '#FFBC99',
        counter: 550000,
        width: '20%',
      },
      {
        title: 'alexmchan',
        color: '#B1E5FC',
        counter: 512350,
        width: '20%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '60%',
      },
    ],
  },
  {
    id: 6,
    product: 'DSM - Geometry pattern',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-1.jpg',
    image2x: '/images/content/product-pic-1@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Market',
        color: '#FFBC99',
        counter: 550000,
        width: '20%',
      },
      {
        title: 'Social media',
        color: '#CABDFF',
        counter: 320000,
        width: '12%',
      },
      {
        title: 'Direct',
        color: '#B5E4CA',
        counter: 650550,
        width: '30%',
      },
      {
        title: 'alexmchan',
        color: '#B1E5FC',
        counter: 512350,
        width: '20%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '18%',
      },
    ],
  },
  {
    id: 7,
    product: 'Node - Crypto iOS UI design kit',
    date: '25 Sep - 4 Oct',
    image: '/images/content/product-pic-2.jpg',
    image2x: '/images/content/product-pic-2@2x.jpg',
    progress: progress(),
    items: [
      {
        title: 'Social media',
        color: '#CABDFF',
        counter: 320000,
        width: '32%',
      },
      {
        title: 'Direct',
        color: '#B5E4CA',
        counter: 650550,
        width: '30%',
      },
      {
        title: 'alexmchan',
        color: '#B1E5FC',
        counter: 512350,
        width: '20%',
      },
      {
        title: 'Others',
        color: '#FFD88D',
        counter: 498000,
        width: '18%',
      },
    ],
  },
]
