export const transactions = [
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: false,
    price: 98,
    earnings: -9.8,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: false,
    price: 98,
    earnings: -9.8,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: false,
    price: 98,
    earnings: -9.8,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 98,
    earnings: 88.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: true,
    price: 85,
    earnings: 65.2,
  },
  {
    product: 'Fleet - Travel shopping UI Design kit',
    invoice: 'Invoice: alexmchan-8934AS',
    date: '14 Oct 2021',
    status: false,
    price: 98,
    earnings: -9.8,
  },
]
