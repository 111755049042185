import { progress } from '../utils'

export const posts = [
  {
    title:
      'Hurry up! You got 50% off – all items <span role="img" aria-label="fire">🔥</span>',
    image: '/images/content/post-pic-1.jpg',
    image2x: '/images/content/post-pic-1@2x.jpg',
    picture: true,
    distribution: 1.2,
    socials: [
      {
        title: 'facebook',
        url: 'https://www.facebook.com/dashboard-a.alexmchan.com/',
      },
      {
        title: 'twitter',
        url: 'https://twitter.com/alexmchan',
      },
    ],
    linkClicks: {
      counter: 26,
      balance: 37.8,
      progress: progress(),
    },
    views: {
      counter: 256,
      balance: 17.8,
      progress: progress(),
    },
    engagement: {
      counter: 15,
      balance: -37.8,
      progress: progress(),
    },
  },
  {
    title: 'HTML version has been released',
    image: '/images/content/post-pic-2.jpg',
    image2x: '/images/content/post-pic-2@2x.jpg',
    video: true,
    distribution: 1.6,
    socials: [
      {
        title: 'twitter',
        url: 'https://twitter.com/alexmchan',
      },
    ],
    linkClicks: {
      counter: 16,
      balance: 24.5,
      progress: progress(),
    },
    views: {
      counter: 334,
      balance: -1.8,
      progress: progress(),
    },
    engagement: {
      counter: 15,
      balance: +12.9,
      progress: progress(),
    },
  },
  {
    title:
      'Hurry up! You got 50% off – all items <span role="img" aria-label="fire">🔥</span>',
    image: '/images/content/post-pic-3.jpg',
    image2x: '/images/content/post-pic-3@2x.jpg',
    picture: true,
    distribution: -1.5,
    socials: [
      {
        title: 'facebook',
        url: 'https://www.facebook.com/dashboard-a.alexmchan.com/',
      },
      {
        title: 'twitter',
        url: 'https://twitter.com/alexmchan',
      },
    ],
    linkClicks: {
      counter: 123,
      balance: -14.5,
      progress: progress(),
    },
    views: {
      counter: 98,
      balance: -12.8,
      progress: progress(),
    },
    engagement: {
      counter: 25,
      balance: +1.8,
      progress: progress(),
    },
  },
  {
    title: 'HTML version has been released',
    image: '/images/content/post-pic-4.jpg',
    image2x: '/images/content/post-pic-4@2x.jpg',
    video: true,
    distribution: 1.9,
    socials: [
      {
        title: 'twitter',
        url: 'https://twitter.com/alexmchan',
      },
    ],
    linkClicks: {
      counter: 16,
      balance: 24.5,
      progress: progress(),
    },
    views: {
      counter: 334,
      balance: -1.8,
      progress: progress(),
    },
    engagement: {
      counter: 15,
      balance: +12.9,
      progress: progress(),
    },
  },
  {
    title:
      'Hurry up! You got 50% off – all items <span role="img" aria-label="fire">🔥</span>',
    image: '/images/content/post-pic-5.jpg',
    image2x: '/images/content/post-pic-5@2x.jpg',
    picture: true,
    distribution: -1.1,
    socials: [
      {
        title: 'facebook',
        url: 'https://www.facebook.com/dashboard-a.alexmchan.com/',
      },
      {
        title: 'twitter',
        url: 'https://twitter.com/alexmchan',
      },
    ],
    linkClicks: {
      counter: 55,
      balance: 14.5,
      progress: progress(),
    },
    views: {
      counter: 78,
      balance: 12.8,
      progress: progress(),
    },
    engagement: {
      counter: -25,
      balance: +1.8,
      progress: progress(),
    },
  },
]
